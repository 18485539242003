var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"nodeForm",attrs:{"rules":_vm.rules,"label-position":"top","label-width":"100px","model":_vm.nodeToBind,"hide-required-asterisk":false},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();$event.stopPropagation();}}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"node_name","label":_vm.__('Name')}},[_c('el-input',{model:{value:(_vm.nodeToBind.node_name),callback:function ($$v) {_vm.$set(_vm.nodeToBind, "node_name", $$v)},expression:"nodeToBind.node_name"}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{staticClass:"is-required",attrs:{"prop":"knowledge_group_id","label":_vm.__('Knowledge Group')}},[_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.knowledgeGroupLoading),expression:"knowledgeGroupLoading"}],staticStyle:{"width":"100%"},attrs:{"id":'knowledgeGroupSelect'},on:{"change":_vm.knowledgeGroupChange},model:{value:(
              _vm.nodeToBind.knowledge_distiller_node.data.knowledge_group_id
            ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.knowledge_distiller_node.data, "knowledge_group_id", $$v)},expression:"\n              nodeToBind.knowledge_distiller_node.data.knowledge_group_id\n            "}},_vm._l((_vm.knowledgeGroups),function(group){return _c('el-option',{key:group.knowledgeGroupId,attrs:{"label":group.name,"value":group.knowledgeGroupId}})}),1)],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"prior_conversation_steps","label":_vm.__('Prior Conversation Steps')}},[_c('el-slider',{attrs:{"show-input":"","max":_vm.maxAllowedSteps},model:{value:(
              _vm.nodeToBind.knowledge_distiller_node.data
                .prior_conversation_steps
            ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.knowledge_distiller_node.data
                , "prior_conversation_steps", $$v)},expression:"\n              nodeToBind.knowledge_distiller_node.data\n                .prior_conversation_steps\n            "}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{staticClass:"is-required",attrs:{"prop":"query_text","label":_vm.__('Query Text')}},[_c('input-variable-popper',{attrs:{"force-reinitialize":"","value":"query_text"},model:{value:(_vm.nodeToBind.knowledge_distiller_node.data.query_text),callback:function ($$v) {_vm.$set(_vm.nodeToBind.knowledge_distiller_node.data, "query_text", $$v)},expression:"nodeToBind.knowledge_distiller_node.data.query_text"}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"fetch_timeout","label":_vm.__('Fetch Timeout')}},[_c('el-slider',{attrs:{"show-input":"","max":_vm.maxAllowedTimeout},model:{value:(_vm.nodeToBind.knowledge_distiller_node.data.fetch_timeout),callback:function ($$v) {_vm.$set(_vm.nodeToBind.knowledge_distiller_node.data, "fetch_timeout", $$v)},expression:"nodeToBind.knowledge_distiller_node.data.fetch_timeout"}})],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[(this.task_type && this.task_type === 'voice')?_c('el-form-item',{staticStyle:{"margin-top":"20px"},attrs:{"prop":"music_on_hold","label":_vm.__('Music On Hold')}},[_c('wav-file-uploader',{attrs:{"folder":`tasks/${_vm.task_id}/knowledge_distiller_node/moh`,"file-url":_vm.musicOnHoldUrl,"file-name":_vm.musicOnHoldName,"max-size-in-mb":3},on:{"on-success":_vm.handleMOHUploadSuccess,"on-delete":_vm.handleMOHFileRemove}})],1):_vm._e()],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"top_search_results","label":_vm.__('Top Search Results (Top-K)')}},[_c('el-slider',{attrs:{"show-input":"","max":_vm.maxAllowedResults},model:{value:(
              _vm.nodeToBind.knowledge_distiller_node.data.top_search_results
            ),callback:function ($$v) {_vm.$set(_vm.nodeToBind.knowledge_distiller_node.data, "top_search_results", $$v)},expression:"\n              nodeToBind.knowledge_distiller_node.data.top_search_results\n            "}})],1)],1)],1),_c('el-row',{staticStyle:{"padding-top":"10px"},attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{staticClass:"is-required",attrs:{"label":_vm.__('Assign Response to Variable'),"prop":"response_var"}},[_c('create-or-select',{attrs:{"items":_vm.singleValuedVariables,"label":"variable_name","value":"variable_id","placeholder":_vm.__('Select or create a new variable'),"new-item-message":_vm.__('new variable'),"current_select":_vm.selectedVariableForResponse},on:{"change":function($event){_vm.handleVariableChange($event)(
                _vm.knowledgeDistillerVariableRuleValue
              )}}}),_c('el-col',{attrs:{"span":4}},[(_vm.isNewVariableCreatedToStoreResponse)?_c('span',{staticClass:"row-message"},[_vm._v(" "+_vm._s(_vm.__("new variable"))+" ")]):_vm._e()])],1)],1)],1),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"prop":"knowledge_distiller_node.data.no_match_canvas_id"}},[_c('EventHandlers',{attrs:{"event-handler-canvas":_vm.noMatchEventHandlerCanvas,"show-event-handler-count":false,"eventHandlerLabel":_vm.__('No Match Canvas'),"event-handler-place-holder":_vm.__('Select or create a no match event handler canvas'),"cssClass":"is-required"},on:{"update-event-handler":function($event){return _vm.setNoMatchEventHandlerCanvas($event)}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }